import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'shared_components/public/assets/Logo.png';
import LoginPageLayout from 'shared_components/PageLayout/LoginPageLayout';
import WContainer from 'shared_components/components/WContainer';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { EOIForm } from 'shared_components/components/EOIForm/EOIForm';
import { useApi } from 'shared_components/context';

function EOIBox() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { clientApi: api } = useApi();
  const emptyState = {
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    aliases: [],
    pplPerformerName: '',
    pplPerformerId: '',
  };
  const [formData, setFormData] = useState(emptyState);

  const handleSubmit = async () => {
    return api
      .createClientInterestRegistration({
        clientInterestRegistration: {
          ...formData,
          aliases: formData.aliases.filter((alias) => alias !== ''),
        },
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(async (data) => {
        const errors = await data.response.json();

        // Format server-side validation errors for setting in form context
        const newErrors = { ...errors };
        if (errors.aliases) {
          Object.entries(errors.aliases as { [index: string]: string[] }).map(
            ([i, messages]) => {
              newErrors[`aliases.${i}`] = messages[0];
            }
          );
          delete newErrors.aliases;
        }

        return Promise.reject(newErrors);
      });
  };

  const onSuccess = () => {
    setFormData(emptyState);
    setShowSuccessModal(true);
  };

  return (
    <LoginPageLayout>
      <div className="tw-flex tw-flex-col tw-items-center">
        <WContainer extraClasses="tw-w-[600px]sm:tw-w-50">
          <div className="tw-flex tw-flex-col tw-items-center">
            <img
              src={Logo}
              alt="Westbury Music Consultants"
              className="tw-w-[158px] tw-h-[56px] tw-mt-6"
            />
            <div className="tw-flex tw-flex-col tw-items-center tw-mt-6">
              <p className="tw-mb-6 tw-text-[20px] tw-uppercase tw-font-semibold">
                Want a free review?
              </p>
              <p className="tw-mb-6 tw-font-light">
                Enter your details to get a free PPL review
              </p>
              <h1 className="tw-font-sans tw-text-2xl tw-font-semibold">
                Performer/Producer
              </h1>
            </div>
            <div className="tw-mt-6">
              <EOIForm
                handleSubmit={handleSubmit}
                onSuccess={onSuccess}
                onSuccessMessage={
                  <WModal
                    title=""
                    isOpen={showSuccessModal}
                    onClose={() => setShowSuccessModal(false)}
                  >
                    <ThankyouBlock />
                  </WModal>
                }
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-6">
            <div className="tw-text-white tw-text-sm tw-w-[350px] tw-text-center tw-mt-6">
              <p>
                By expressing my interest with WMC I agree to the terms and
                conditions and privacy policy
              </p>
            </div>
          </div>
        </WContainer>
        <div className="tw-text-white tw-flex tw-mt-4">
          <p>
            Already have an account?
            <Link className="tw-underline" to="/signin">
              {'  '}Log In Here
            </Link>
          </p>
        </div>
      </div>
    </LoginPageLayout>
  );
}

export default EOIBox;
